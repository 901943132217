<template>
  <div>
    <div
      class="content"
      style="padding-bottom: 60px"
    >
      <div class="materialShop_order">
        <div class="materialShop_order_content">
          <a-row
            type="flex"
            justify="space-between"
          >
            <a-col :span="3">
              <div class="materialShop_address_title">订单信息</div>
            </a-col>
          </a-row>
          <div>
            <div>
              <a-row>
                <a-col :span="3">
                  <div class="materialShop_car_img">
                    <img
                      :src="pageData.detailsImg ? pageData.detailsImg[0] : null"
                      alt
                    />
                  </div>
                </a-col>
                <a-col :span="12">
                  <div class="materialShop_car_title">{{ pageData.title }}</div>
                  <a-row>
                    <span>文件大小：{{ pageData.fileSize }}</span>
                    <span style="margin-left: 8px">文件类型：{{ pageData.fileType }}</span>
                  </a-row>
                </a-col>
                <a-col :span="3">
                  <div class="materialShop_car_text">￥{{ isVip ? pageData.memberPrice : pageData.price }}</div>
                </a-col>
                <a-col :span="3">
                  <div class="materialShop_car_text">1</div>
                </a-col>
                <a-col :span="3">
                  <div
                    class="materialShop_car_text"
                    style="color: #ff5555"
                  >￥{{ isVip ? pageData.memberPrice : pageData.price }}</div>
                </a-col>
              </a-row>
            </div>
          </div>
        </div>
        <a-row
          type="flex"
          justify="end"
        >
          <a-col :span="3">
            <div class="materialShop_order_text" style="font-size:20px">订单总额：{{ isVip ? pageData.memberPrice : pageData.price }}</div>
          </a-col>
        </a-row>
        <div class="materialShop_order_bottom">
          <a-row
            type="flex"
            justify="end"
          >
            <a-col :span="6">
              <div class="materialShop_order_text">
                应付总额：
                <span style="color:#ff5555;font-size: 20px">￥{{ isVip ? pageData.memberPrice : pageData.price }}</span>
              </div>
            </a-col>
          </a-row>
        </div>
        <a-row
          type="flex"
          justify="end"
        >
          <a-col :span="3">
            <a-button
              type="primary"
              class="materialShop_order_btn"
              @click="submit"
              :loading="loading"
            >提交订单</a-button>
          </a-col>
        </a-row>
      </div>
    </div>
    <a-modal
      title="订单提交成功"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div class="modal_text">已生成资料购买订单，请前往控制台 => 学习资料 => 资料订单中支付订单</div>
      <div>
        <router-link
          to="/learnMsg/learnMsgorderMsg"
          style="margin-left:175px;font-size:18px"
        >前往支付订单>></router-link>
      </div>
    </a-modal>
    <addressForm
      ref="addressForm"
      @success="handleGetAddressList"
    />
  </div>
</template>

<script>
import { addOrder, weChatPay, Addresslist, enterpriseOrderController } from '@/api/materialShop'
import { employmentLearnUserDetail } from '@/api/msg'
import { dateFormat } from '@/common/common'
import moment from 'moment'
import addressForm from '@/views/materialDemand/address/form.vue'

export default {
  name: 'Index',
  components: {
    addressForm
  },
  data () {
    return {
      deliveryTime: '',
      visible: false,
      supplierName: '',
      orderProductList: [],
      addressList: [],
      totalPrice: '',
      addressParams: {
        pageIndex: 1,
        pageSize: 4
      },
      isList: true,
      pageData: {},
      text: '获取全部地址',
      address: {},
      orderListDtoList: [],
      loading: false,
      mark: ''
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    disabledDate (current) {
      return moment(current).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')
    },
    getMore () {
      this.text = '已展示全部地址'
      this.addressParams.pageSize = 100
      this.handleGetAddressList()
    },
    handleGetAddressList () {
      Addresslist(this.addressParams).then(res => {
        this.addressList = res.data
        console.log(res)
      })
    },
    getAddress (item) {
      this.address = item
    },
    init () {
      employmentLearnUserDetail({
        id: this.$route.query.id
      }).then(res => {
        this.pageData = res.data
      })
      //   this.orderProductList = JSON.parse(this.$route.query.data)
      //   this.totalPrice = this.$route.query.totalPrice
      this.handleGetAddressList()
    },
    submit () {
      this.loading = true
      const orderListDtoList = [{
        orderAddCmd: {
          orderType: '0',
          clientId: 'enterprise',
          /** 订单金额 */
          amount: this.isVip ? this.pageData.memberPrice : this.pageData.price,
          totalAmount: this.isVip ? this.pageData.memberPrice : this.pageData.price,
          /** 发货时间 */
          deliveryTime: dateFormat(this.deliveryTime._d) + ' 00:00:00',
          /** 订单状态; */
          orderStatus: '0',
          /** 收货人 */
          consignee: this.address.consignee,
          /** 收货人电话 */
          phone: this.address.phone,
          shippingAddress: this.address.province + this.address.city + this.address.county + this.address.address,
          supplierId: null,
          supplierName: null
        },
        orderProductAddCmdList: [{
          num: 1,
          price: this.isVip ? this.pageData.memberPrice : this.pageData.price,
          productId: this.pageData.id,
          productName: this.pageData.title,
          productSpecs: this.pageData.fileType
        }]
      }]
      addOrder({ orderListDtoList })
        .then(res => {
          if (res.success) {
            this.loading = false
            this.visible = true
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleOk () {
      this.visible = false
      history.go(-2)
    },
    handleCancel () {
      this.visible = false
      history.go(-2)
    },
    onChange () { }
  }
}
</script>

<style lang="less">
@import url('../../common/style');
@import url('../materialShop/search/index');
.text--overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
